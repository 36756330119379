<template>
  <DsSection
    :theme="isScreenSmall && !isFormulaPage ? 'light' : 'grey'"
    :with-space-top="!isFormulaPage"
  >
    <div
      class="ds-grid"
      :class="isFormulaPage ? 'ds-background-color-grey' : 'ds-background-color-white ds-padding-y-48 ds-md-padding-y-96'"
    >
      <div v-if="title" class="ds-grid__col ds-grid__col--12 ds-md-grid__col--9 ds-md-grid__col--offset-1 ds-margin-bottom-48">
        <h3
          v-if="title"
          class="ds-heading"
          v-text="title"
        />
        <RichText
          v-if="description"
          :data="description"
          class="ds-margin-top-12 ds-text"
        />
      </div>
      <div :class="{ 'ds-grid__col ds-grid__col--12 ds-md-grid__col--10 ds-md-grid__col--offset-1 wds-grid__col--no-gutter': !isFormulaPage}">
        <DsGrid
          :items="items"
          :column-width="isFormulaPage ? { small: 6, medium: 4, large: 3 } : { small: 6, medium: 4, large: 4 }"
        >
          <template #default="{ item }">
            <DsIcon
              :category="item.icon.category"
              :name="item.icon.name"
              class="ds-color-green ds-margin-bottom-12"
            />
            <RichText :data="item.shortDescription" />
          </template>
        </DsGrid>
      </div>
    </div>
  </DsSection>
</template>

<script setup>
import { DsGrid, DsIcon, DsSection } from 'awds'
const { isScreenSmall } = useScreen()

defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: Object,
    default: null
  },
  items: {
    type: Array,
    default: () => ([])
  },
  isFormulaPage: {
    type: Boolean,
    default: false
  }
})

</script>
